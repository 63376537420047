body, html {
  width: 100vw;
  height: 100vh;
}

div#root {
  width: 100%;
  height: 100%;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.img-wrapper {
  position: relative;
  background-color: blue;
}
.container img {
  width: 500px;
  aspect-ratio: auto;
}
.y-axis {
  width: 3px;
  height: 100%;
  position: absolute;
  background-color: green;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.x-axis {
  height: 3px;
  width: 100%;
  position: absolute;
  background-color: red;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.hotspot {
  background-color: #FFF;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0,0,0,.2);
  transform: translate(-50% -50%);
}
pre {
  max-width: 400px;
}
.text-wrapper {
  display: flex;
  justify-content: center;
}
.hotspots-text {
  width: 440px;
  height: 300px;
}

.file-input-control {
  margin-top: 100px;
}